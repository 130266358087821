<template>
  <div>
    <StoreSearch @handlesearchStore="handlesearchStore"></StoreSearch>
    <StoreInfo
      v-if="isShow"
      @handlesaveStoreSetting="handlesaveStoreSetting"
      :formObj="storeObj"
    ></StoreInfo>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";

import StoreSearch from "./childComps/StoreSearch.vue";
import StoreInfo from "./childComps/StoreInfo.vue";

import { getUserInfo } from "@/utils/api";
import { changeTips } from "@/utils/util";
import axios from "axios";

export default {
  components: {
    BCard,
    StoreSearch,
    StoreInfo,
  },
  data() {
    return {
      storeObj: null,
      isShow: false,
    };
  },
  methods: {
    //搜索
    handlesearchStore(params) {
      console.log(params, "传递的参数");
      getUserInfo("sysAdminWeb.getStoreInfo", params).then((res) => {
        if (res && res.data && res.data.code === "200") {
          console.log(res, "搜索");
          this.storeObj = res.data.data;
          this.isShow = true;
        } else {
          return changeTips(res.data.msg, "warning");
        }
      });
    },
    //保存
    handlesaveStoreSetting(storeInfoForm) {
      // console.log(storeInfoForm, "提交的表单数据");
      getUserInfo("sysAdminWeb.updateStoreInfo", storeInfoForm).then((res) => {
        if (res && res.data && res.data.code === "200") {
          // console.log(res, "保存成功");
          this.handlesearchStore({storeId:storeInfoForm.sid})
          return changeTips(res.data.msg, "primary");
        } else {
          return changeTips(res.data.msg, "warning");
        }
      });
    },
        // test() {
    //   axios
    //     .get("/province.json")
    //     .then((result) => {
    //       console.log("getMenuData", result);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
</script>

<style>
</style>
