<template>
  <b-row>
    <b-col md="10" xl="10">
      <b-card>
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="4">
              <b-form-group label="门店ID" label-for="v-storeId">
                <b-form-input
                  id="v-storeId"
                  type="number"
                  placeholder="请输入门店ID"
                  disabled
                  v-model="storeInfoForm.sid"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="店名" label-for="v-storeName">
                <b-form-input
                  id="v-storeName"
                  placeholder="请输入店名"
                  :disabled="disabled"
                  v-model="storeInfoForm.storeName"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="联系电话" label-for="v-phone">
                <b-form-input
                  id="v-phone"
                  type="number"
                  placeholder="请输入联系电话"
                  :disabled="disabled"
                  v-model="storeInfoForm.phone"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="4">
              <b-form-group label="省" label-for="v-addressProvince">
                <b-form-input
                  id="v-addressProvince"
                  placeholder="请输入省"
                  :disabled="disabled"
                  v-model="storeInfoForm.addressProvince"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="市" label-for="v-addressCity">
                <b-form-input
                  id="v-addressCity"
                  placeholder="请输入市"
                  :disabled="disabled"
                  v-model="storeInfoForm.addressCity"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="区县" label-for="v-addressArea">
                <b-form-input
                  id="v-addressArea"
                  placeholder="请输入区县"
                  :disabled="disabled"
                  v-model="storeInfoForm.addressArea"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="地址明细" label-for="v-addressDetail">
                <b-form-input
                  id="v-addressDetail"
                  placeholder="请输入地址明细"
                  :disabled="disabled"
                  v-model="storeInfoForm.addressDetail"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="地图坐标" label-for="v-location">
                <b-form-input
                  id="v-location"
                  placeholder="请输入地图坐标"
                  :disabled="disabled"
                  v-model="storeInfoForm.location"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col cols="10">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="saveStoreSetting"
              >
                保存
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="editStoreInfo"
              >
                编辑
              </b-button> -->
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    formObj: {
      type: Object,
    },
  },
  data() {
    return {
      disabled: false,
      storeInfoForm: this.formObj,
    };
  },
  watch: {
    //搜索内容发生变化，重新赋值
    formObj() {
      this.storeInfoForm = this.formObj;
    },
  },
  created() {
    // console.log(this.storeInfoForm,'storeInfoForm');
  },
  methods: {
    saveStoreSetting() {
      const storeParams = {};
      storeParams.sid = this.storeInfoForm.sid //门店id
      storeParams.storeName = this.storeInfoForm.storeName //店名
      storeParams.addressProvince = this.storeInfoForm.addressProvince  //省
      storeParams.addressCity = this.storeInfoForm.addressCity //市
      storeParams.addressArea = this.storeInfoForm.addressArea //区or县
      storeParams.addressDetail = this.storeInfoForm.addressDetail //地址明细
      storeParams.phone = this.storeInfoForm.phone //手机
      storeParams.location = this.storeInfoForm.location //地图坐标
      this.$emit("handlesaveStoreSetting", storeParams);
    },
  },
};
</script>